<template>
  <div class="block" :class="this.class"
       :style="'--topLeftCorner:'+this.topLeftCorner+ '; --topRightCorner:'+this.topRightCorner+'; --bottomLeftCorner:'+this.bottomLeftCorner+'; --bottomRightCorner:'+this.bottomRightCorner+'; --width:'+width+'; --height:'+height">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Block',
  props: {
    class: {
      required: true,
      type: String,
    },
    topLeftCorner: {
      required: false,
      type: String,
      default: "50px"
    },
    topRightCorner: {
      required: false,
      type: String,
      default: "50px"
    },
    bottomLeftCorner: {
      required: false,
      type: String,
      default: "50px"
    },
    bottomRightCorner: {
      required: false,
      type: String,
      default: "50px"
    },
    width: {
      required: false,
      type: String,
      default: "273px"
    },
    height: {
      required: false,
      type: String,
      default: "234px"
    }
  }
}
</script>

<style scoped>

.block {
  width: var(--width);
  height: var(--height);
  border-radius: var(--topLeftCorner) var(--topRightCorner) var(--bottomRightCorner) var(--bottomLeftCorner);
}

</style>