<template>
  <div class="switch" @click="openLink()">
    <div class="switch__text">
      {{ text }}
    </div>
    <div class="switch__circle">
      <font-awesome-icon :icon="icon"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Switch',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false
    }
  },
  methods: {
    openLink() {
      if (this.link !== undefined) {
        window.open(this.link);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.switch {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > &__text {
    @include lighter($color_call2_action_2, 5%)
  }
  &:hover > &__circle {
    @include lighter($color_call2_action, 5%)
  }

  &__text {
    position: relative;
    right: -10px;
    padding-right: 30px;
    padding-left: 20px;
    border-radius: 25px 0 0 25px;
    height: 60px;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    background-color: $color_call2_action_2;
    color: white;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;

    &:hover {
      cursor: pointer;
    }

    @include for-phone-only {
      height: 32px;
      font-size: 14px;
      padding-right: 18px;
      padding-left: 12px;
    }
  }

  &__circle {
    &:hover {
      cursor: pointer;
    }
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: $color_call2_action;
    height: 120px;
    width: 120px;
    font-size: 48px;
    border-radius: 50% !important;
    color: #E4E4E4;
    z-index: 2;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25), -4px 0px 4px rgba(0, 0, 0, 0.15);

    @include for-phone-only {
      height: 64px;
      width: 64px;
      font-size: 26px;
    }
  }
}

</style>
