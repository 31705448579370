<template>
  <div>
    <slot/>
    <div class="section-wrapper">
    </div>
  </div>
</template>

<script>

export default {
  name: 'Section',
  components: {}
}
</script>


<style scoped>


.section-title-image {
  max-width: 100%;
}

.section-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
}

/*
.rotated {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: 134px;
  background-color: #FAFAFA;
  transform: rotate(-4deg);
}

.rotated-bottom {
  z-index: 1;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: 134px;
  background-color: #FAFAFA;
  margin-top: -45px;
  transform: rotate(-4deg);
}


.context {
  position: relative;
  z-index: 2;
}*/

</style>
