<template>
  <div class="service-card">
    <img class="service-card-iamge" :src="image"/>
    <p class="service-card-title">{{ title }}</p>
    <a class="service-card-message">{{message}}</a>
  </div>
</template>

<script>

export default {
  name: 'ServiceCard',
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    }
  }
}

</script>

<style lang="scss" scoped>

.service-card {
  &:hover {
    @include lighter(#171717, 10%);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.22);
    margin-top: 10px;
    transition: all 500ms;
  }
  width: 267px;
  height: 297px;
  background: #171717;
  border-radius: 10px;
  margin: 12px;
  display: inline-block;
  box-shadow: 0px 2px 10px rgba(145, 145, 145, 0.15);

  &-iamge {
    width: 267px;
    height: 170px;
    background-size: cover;
    border-radius: 10px 10px 0px 0px;
  }

  &-title {
    margin: 15px 10px 0;
    font-family: Monte, serif;
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #f5f5f5;
  }

  &-message {
    margin: 0px 10px;
    font-family: Monte, serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #e3e3e3;
    display: block;
  }
}
</style>
