<template>
  <div class="elementor-shape" :class="'elementor-shape-'+position+'-'+direction" :style="'--color:'+color" data-negative="false">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
      <path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'Shape',
  props: {
    position: {
      type: String,
      required: true,
    },
    direction: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'red',
    }
  }
}

</script>

<style scoped>

.elementor-shape-bottom:not([data-negative="true"]) svg {
  z-index: -1;
}

.elementor-shape .elementor-shape-fill {
  fill: black;
  -webkit-transform-origin: center;
  -ms-transform-origin: center;
  transform-origin: center;
}

.elementor-shape-bottom-left,
.elementor-shape-bottom-right {
  position: absolute;
  bottom: 0;
  transform: rotateY(180deg);
}

.elementor-shape-bottom-left svg path {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.elementor-shape-bottom-right svg path {
  -webkit-transform: rotateY(0deg) scaleX(-1);
  transform: rotateY(0deg) scaleX(-1);
}

.elementor-shape-top-left,
.elementor-shape-top-right {
  position: absolute;
  top: 0;
  transform: rotateY(0deg) !important;
}

.elementor-shape-top-left svg path {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.elementor-shape-top-right svg path {
  -webkit-transform: rotateY(180deg) scaleX(-1);
  transform: rotateY(180deg) scaleX(-1);
}

.elementor-shape svg {
  display: block;
  width: calc(100% + 1.3px);
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.elementor-shape {
  transform: rotate(180deg);
  overflow: hidden;
  left: 0;
  width: calc(100% + .974px);
  line-height: 0;
  direction: rtl;
  margin-bottom: -1px;
}

</style>
