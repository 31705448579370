<template>
  <div class="main__head texticon">
    <font-awesome-icon v-if="hasIcon" :icon="icon" class="texticon" :class="iconClass"></font-awesome-icon>
    <a v-if="!hasIcon" class="texticon icon" :class="numberClass">{{ number }}</a>
    <div class="texticon" :class="textClass">
      <slot/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TextIcon',
  props: {
    icon: {
      type: String,
      required: false,
      default: 'not set'
    },
    iconClass: {
      type: String,
      required: false,
      default: ''
    },
    number: {
      type: Number,
      required: false,
      default: 0
    },
    numberClass: {
      type: String,
      required: false,
      default: '',
    },
    textClass: {
      type: String,
      required: false,
      default: '',
    }
  },
  computed: {
    hasIcon() {
      return this.icon !== 'not set';
    }
  }
}

</script>

<style lang="scss" scoped>
.main__head {
  display: flex !important;

  &:hover {
    filter: drop-shadow(2px 4px 8px #a6a6a6);
    transition: all ease-in-out 250ms;
  }
}

.texticon {
  vertical-align: middle;
  display: inline-block;
  color: #eaeaea;
}

.icon {
  text-align: center;
  margin-right: 1rem;
}
</style>
