<template>
  <div class="gradient-parent">
    <div class="gradient-child">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gradient'
}
</script>

<style scoped>

.gradient-parent {
  display: flex;
  display: -webkit-flex;
  margin: auto;
  padding: 0;
  height: 76px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.76) 0%, rgba(104, 104, 104, 0) 100%);
  border-radius: 0px;
}

.gradient-child {
  margin: auto 0;
  color: #fff;
  font-family: "Monte", serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

}

</style>
