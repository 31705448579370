<template>
  <div class="main">
    <div class="preview">
      <video class="preview_video" src="/video/car.mp4" autoplay loop muted></video>
      <div class="preview-gradient__wrapper">
        <div class="preview-gradient__child">
          <Gradient>
            <span class="preview-gradient__text">Wir helfen, wenn's mal kracht!</span>
          </Gradient>
        </div>
        <div class="preview-gradient__child">
          <Gradient>
            <span class="preview-gradient__text">Geprüft und Zertifiziert</span>
          </Gradient>
        </div>
        <div class="preview-gradient__child">
          <Gradient>
            <span class="preview-gradient__text">Umkreis Berlin & Brandenburg</span>
          </Gradient>
        </div>
      </div>
      <div class="preview-action">
        <div class="preview-action__wrapper">
          <div class="preview-action__item">
            <Switch class="preview-action__buttons" icon="phone" text="Jetzt anrufen"
                    link="tel://+491625112511"></Switch>
          </div>
          <div class="preview-action__item">
            <Switch class="preview-action__buttons" :icon="['fab', 'whatsapp']" text="Jetzt anschreiben"
                    link="https://wa.me/+491625112511"></Switch>
          </div>
        </div>
      </div>
      <Shape position="bottom" direction="right" color="#FAFAFA"/>
    </div>
    <div class="big-boxes">
      <div class="big-boxes__row">
        <Block class="big-boxes__box--bg" width="273px" height="234px" top-right-corner="0" bottom-right-corner="0"
               @click="openLink('https://wa.me/+491625112511', 'blank')">
          <font-awesome-icon class="row--icon" :icon="['fab', 'whatsapp']"/>
          <h4 style="text-align: center; color: antiquewhite">WhatsApp Kontakt<br><small>Kontaktiere uns mit einem
            klick.</small>
          </h4>
        </Block>
        <Block class="big-boxes__box--bg" width="317px" height="269px" style="padding: 2rem;">
          <img src="/images/logo.png" style="margin: 10px auto 0 auto; height: 100%;width: 100%;"/>
          <h4 style="text-align: center; color: antiquewhite">24/7 erreichbar</h4>
        </Block>
        <Block class="big-boxes__box--bg" width="273px" height="234px" top-left-corner="0" bottom-left-corner="0">
          <h3>Kontaktiere uns</h3>
          <ul class="big-boxes__box--list">
            <li class="big-boxes__box--item">
              <TextIcon icon="mobile-alt" icon-class="big-boxes__box--item-icon">
                <span style="margin-left: 5px">Mobil: 0162 511 2 511</span>
              </TextIcon>
            </li>
            <li class="big-boxes__box--item">
              <TextIcon icon="phone" icon-class="big-boxes__box--item-icon">
                Festnetz: 030 1235 6761
              </TextIcon>
            </li>
            <li class="big-boxes__box--item">
              <TextIcon icon="location-arrow" icon-class="big-boxes__box--item-icon">
                Standort: Böttgerstrasse 7, 13357 Berlin
              </TextIcon>
            </li>
          </ul>
        </Block>
      </div>
    </div>
    <section class="section">
      <h2 class="section__headline" id="ablauf">Unser Ablauf</h2>

      <ul class="process__list">
        <li class="process__item">
          <TextIcon :number="1" number-class="process__item--icon" text-class="process__item--text">
            Es ist zu einem Unfall gekommen oder Sie suchen einen Gutachter der sich ihren Fall genauer anschaut?
            Dann können Sie unten in dem Kontaktformular Ihre Daten angeben und wir melden uns bei Ihnen.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon :number="2" number-class="process__item--icon" text-class="process__item--text">
            Ein Mitarbeiter wird sich dann schnellstmöglich bei Ihnen melden. Sie erzählen uns dann alles
            zu Ihrem Unfall und wir werden eine erste Einschätzung abgeben können.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon :number="3" number-class="process__item--icon" text-class="process__item--text">
            Wir werden dann gemeinsam mit Ihnen einen Termin an einen Ort den Sie sich wünschen vereinbaren.
            Bevorzugen tun wir aber einen Termin bei uns, da wir hier auch ihr Fahrzeug auf eine Hebebühne fahren
            können,
            um auch von unten zu begutachten.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon :number="4" number-class="process__item--icon" text-class="process__item--text">
            Bei unserem Termin treffen wir uns an dem genannten Ort und wir fangen an das Gutachten zu erstellen.
            Dabei wird Ihr Fahrzeug von uns einmal komplett unter die Lupe genommen.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon :number="5" number-class="process__item--icon" text-class="process__item--text">
            Anschließen sichern wir alle nötigen Fotos vom Unfall und gehen zum nächsten Schritt über. Ein
            Rechtssicheres Gutachten wird schnellstmöglich erstellt und die Abwicklung übernehmen wir mit der
            Versicherung.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon :number="6" number-class="process__item--icon" text-class="process__item--text">
            Nun können Sie sich für zwei Optionen entscheiden. Möchten Sie lieber eine direkte Auszahlung erhalten oder
            doch lieber das KfZ reparieren lassen? Sie entscheiden!
          </TextIcon>
        </li>
      </ul>
    </section>
    <Section class="section">
      <h2 class="section__headline">Unsere Services</h2>
      <div class="service-list">
        <div class="service-list__item">
          <ServiceCard image="/images/evgeny.jpg" title="Der Unfall passierte. Was jetzt?"
                       message="Nehmen Sie schnellstmöglich Kontakt mit uns auf. Wir können Sie sicher beruhigen und Ihnen weiterhelfen."/>
        </div>
        <div class="service-list__item">
          <ServiceCard image="/images/hu-jiarui-kkQU74Kr98w-unsplash.jpg" title="Begutachtung"
                       message="Unter umständen erscheinen wir schnell am Unfallort oder an deinem Unfallwagen und begutachten den Schaden."/>
        </div>
        <div class="service-list__item">
          <ServiceCard image="/images/clark-van-der-beken-CSkriQWeTVs-unsplash.jpg" title="Beweisfotos"
                       message="Wir sichern alle nötigen Fotos vom Unfall und gehen zum nächsten Schritt über."/>
        </div>
        <div class="service-list__item">
          <ServiceCard image="/images/bmw.jpg" title="Sicheres Gutachten"
                       message="Schnellstmöglich erstellen wir Ihnen ein rechtssicheres Gutachten und übernehmen alles mit der Versicherung."/>
        </div>
        <div class="service-list__item">
          <ServiceCard image="/images/sid-ramirez-d56cCiQN45I-unsplash.jpg" title="Schnell vor Ort"
                       message="Wir suchen schnellmöglich den Unfallort oder Unfallwagen auf und erstellen das Gutachten für Sie."/>
        </div>
      </div>
    </Section>
    <section class="section">
      <div class="ani_item_list">
        <div class="ani_item">
          <video src="/video/woman-speaking.mp4" loop autoplay muted></video>
        </div>
        <div class="ani_item">
          <font-awesome-icon icon="car-crash" class="car-text--icon"/>
          <ul class="process__list" style="justify-content: center;">
            <li class="process__item">
              <TextIcon :number="1" number-class="process__item--icon" text-class="process__item--text">
                Absicherung der Unfallstelle
              </TextIcon>
            </li>
            <li class="process__item">
              <TextIcon :number="2" number-class="process__item--icon" text-class="process__item--text">
                Versorgung der Verletzten und Rettungskräfte informieren
              </TextIcon>
            </li>
            <li class="process__item">
              <TextIcon :number="3" number-class="process__item--icon" text-class="process__item--text">
                Unfall mit Fotos und Unfallskizze dokumentieren (Unfallort und Fahrzeuge)
              </TextIcon>
            </li>
            <li class="process__item">
              <TextIcon :number="4" number-class="process__item--icon" text-class="process__item--text">
                Kontaktdaten notieren und Gutachter beauftragen
              </TextIcon>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <section class="section">
      <h2 class="section__headline">Warum wir</h2>
      <ul class="process__list">
        <li class="process__item">
          <TextIcon icon="layer-group" icon-class="process__item--icon" text-class="process__item--layer">
            Im Raum Berlin/Brandenburg sind wir mit einer guten Infrastruktur vertreten. Gibt es einen Unfall im
            Stadtkern Berlin, ist das kein Problem für unsere Speziallisten, genauso wie in abgelegeneren Orten.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon icon="layer-group" icon-class="process__item--icon" text-class="process__item--layer">
            Wir sind Zertifiziert und legen großen Wert auf ausführlichkeit in unserer Expertise. Sollte es einen
            komplexen Schaden geben, werden wir diesen genausten im Detail begutachten.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon icon="layer-group" icon-class="process__item--icon" text-class="process__item--layer">
            Für eine Fahrzeugbewertung ist ein Wertgutachten durch einen unabhängigen Kfz-Gutachter eine zuverlässige
            Quelle. Ein Gutachter erstellt ein Kfz-Gutachten anhand von festgelegten Kriterien. Das Auto-Gutachten ist
            vor allem bei einem Unfall für die Versicherung von Bedeutung.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon icon="layer-group" icon-class="process__item--icon" text-class="process__item--layer">
            Eine Reparaturbestätigung dient im Zusammenhang einer Schadenabwicklung als Nachweis einer durchgeführten
            Reparatur. Hauptsächlich findet sie Anwendung in der fiktiven Schadenabwicklung und als Qualitätskontrolle.
          </TextIcon>
        </li>
        <li class="process__item">
          <TextIcon icon="layer-group" icon-class="process__item--icon" text-class="process__item--layer">
            Außerdem haben wir eine hohe Zufriedensheitsrate, da wir Zuverlässig und schnell vor Ort sind.
          </TextIcon>
        </li>
      </ul>
    </section>
    <section class="section">
      <h1 class="section__headline">Was unsere Kunden über uns sagen</h1>
      <div class="feedback">
        <div class="feedback__item">
          <div class="feedback__item--topBox">
            <div class="feedback__item--name">
              <h2>Alexander Wodarz</h2>
              <small>4. Dezember 2021</small>
            </div>
            <div class="feedback__item--stars">
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
            </div>
          </div>
          <div class="feedback__item--flexFull">
            Der Unfallverursacher hat versucht mir den Schaden vorzuwerfen obwohl ich nachweislich dank dem Gutachter
            nicht Schuld war. Ich bin so dankbar, das hat mir echt meine Probezeit gerettet.
          </div>
        </div>
        <div class="feedback__item">
          <div class="feedback__item--topBox">
            <div class="feedback__item--name">
              <h2>Jean Gräbe</h2>
              <small>9. Dezember 2021</small>
            </div>
            <div class="feedback__item--stars">
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
            </div>
          </div>
          <div class="feedback__item--flexFull">
            Habe mir mal ein Auto von einem Händler gekauft gehabt. Angeblich hieß es Unfallfrei, dann habe ich einen
            Gutachten erstellen lassen und es stellte sich raus, dass das Fahrzeug einen enormen Unfall früher mal
            hatte.
          </div>
        </div>
        <div class="feedback__item">
          <div class="feedback__item--topBox">
            <div class="feedback__item--name">
              <h2>Max Deutschländer</h2>
              <small>1. Januar 2021</small>
            </div>
            <div class="feedback__item--stars">
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
              <font-awesome-icon icon="star"/>
            </div>
          </div>
          <div class="feedback__item--flexFull">
            Mein Nachbar ist mir beim Ausparken reingefahren, dies stellte sich beweislich dank dem Gutachter aus Berlin
            raus. Vielen lieben Dank!
          </div>
        </div>
      </div>
    </section>
    <section class="section" style="display: none">
      <div class="newsletter">
        <h2 class="newsletter__title">Auf dem laufenden bleiben</h2>
        <div class="newsletter__divider"></div>
        <p class="newsletter__text">Abonnieren Sie unseren Newsletter und bleiben Sie auf dem neusten Stand</p>
        <div class="newsletter__form">
          <label>E-Mail</label>
          <div>
            <input placeholder="E-Mail Adresse eingeben"/>
            <button style="margin-left: 3px">
              <font-awesome-icon icon="paper-plane"/>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <h2 class="section__headline">Unser Standort</h2>
      <div class="location" id="location">
        <div style="width: 100%;position: relative;">
          <iframe width="100%" height="500"
                  src="https://maps.google.com/maps?width=100&amp;height=500&amp;hl=en&amp;q=B%C3%B6ttgerstra%C3%9Fe%207%2C%2013357%20Berlin+(Unser%20Standort%3A%20Media%20Corona%20Testzentrum%2006)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed"
                  frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </div>
      </div>
    </section>
    <Contact/>
  </div>
</template>

<script>

import Block from "../components/Block";
import ServiceCard from "../components/ServiceCard";
import Section from "../components/Section";
import TextIcon from "../components/TextIcon";
import Contact from "../components/Contact";
import Gradient from "../components/Gradient";
import Shape from "../components/Shape";
import Switch from "../components/Switch";


export default {
  name: 'Index',
  components: {
    Switch,
    Shape,
    Gradient,
    Contact,
    TextIcon,
    Section,
    ServiceCard,
    Block
  },
  methods: {
    openLink(link) {
      window.open(link, 'blank');
    }
  }
}


</script>

<style lang="scss">

.main {
  overflow: hidden;
}

.newsletter {
  width: 353px;
  margin: 100px auto 110px;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }

  &__divider {
    margin: 0 auto;
    width: 68px;
    height: 0;

    border-top: 3px solid #000000;
  }

  &__text {
    margin-top: 20px;
    width: 353px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
  }

  &__form {
    label {
      width: 27px;
      height: 10px;

      margin-left: 5px;

      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 10px;

      display: flex;
      align-items: center;

      color: #C4C4C4;
      margin-bottom: 5px;
    }

    div {
      display: inline-flex;
      align-content: center;
      align-items: center;
    }

    input {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 10px;
      width: 301px;
      height: 32px;

      background: #E7E7E7;
      border: 1px solid #C4C4C4;
      box-sizing: border-box;
      border-radius: 4px;
    }

    button {
      align-self: center;
      background: none;
      border: none;
      font-size: 25px;
      color: #B1B1B1;
    }
  }
}

.icon-format {
  margin-left: 10px;
  display: inline-block;
  font-family: Monte, serif;
}

.icon {
  font-size: 60px;
  color: rgba(0, 0, 0, 0.15);
}

.center {
  align-items: center;
}

.preview {
  position: relative;
  /*background          : url("/images/car-accident-g502919c63_1920.jpg");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 617px;
  overflow: hidden;

  @include for-big-desktop-up {
    height: 880px;
  }

  &_video {
    width: 100%;
    position: absolute;

    @include for-phone-only {
      width: unset;
      margin-left: -230px;
    }
  }

  &-action {
    &__wrapper {
      position: absolute;
      width: 100%;
      top: 50px;
      right: 50px;
      @include for-phone-only {
        top: unset;
        right: 20px;
        bottom: 150px;
      }
    }

    &__item {
      display: flex;
      justify-content: right;
      margin-bottom: 15px;

      &:nth-child(1) {
        margin-bottom: 2px;
      }

      &:nth-child(2) {
        margin-right: 45px;
      }
    }

    &__text {
      color: white;
      position: relative;
      font-family: Monte, serif;
      font-size: 24px;
      font-weight: 500;
      top: 164px;
      right: 225px;
    }
  }

  &-gradient {
    &__wrapper {
      z-index: 4;
      position: relative;
      display: inline-block;
      padding-top: 50px;

      @include for-phone-only {
        padding-top: 30px !important;
      }

      > .preview-gradient__child {
        &:not(:first-child) {
          padding-top: 20px;
        }

        &:first-child {
          max-width: 620px;
          @include for-phone-only {
            max-width: 390px;
          }
        }

        &:nth-child(2) {
          max-width: 495px;
          @include for-phone-only {
            max-width: 295px;
          }
        }

        &:nth-child(3) {
          max-width: 295px;
          @include for-phone-only {
            max-width: 185px;
          }
        }

        .gradient-child {
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    &__text {
      font-style: italic;
      @include for-phone-only {
        font-size: 18px;
      }
    }
  }
}

.big-boxes {
  position: relative;
  margin-bottom: 175px;

  @include for-phone-only {
    display: none;
  }

  &__row {
    display: flex;
    position: absolute;
    top: calc(100% - 180px);
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;

    .row--icon {
      font-size: 64px;
      color: #25D366;
      border-radius: 5px;
      padding: 14px;
      margin: 0 auto;
      margin-top: 0px;
      display: block;
      margin-top: 18px;
    }
  }

  &__box {
    &--bg {
      &:hover {
        @include lighter($color_call2_action_2, 5%)
      }

      background: $color_call2_action_2;

      &:first-child {
        cursor: pointer;
        z-index: 1;
        box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.25);
      }

      &:nth-child(2) {
        &:hover {
          @include lighter($color_call2_action, 5%)
        }

        z-index: 2;
        background: $color_call2_action;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        overflow: hidden;
        justify-content: center;
        display: inline-grid;
        align-content: center;
      }

      &:last-child {
        z-index: 1;
        box-shadow: 3px 0 10px rgba(0, 0, 0, 0.25);

        h3 {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;

          text-decoration-line: underline;
          color: #FFFFFF;
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

          text-align: center;
        }
      }
    }

    &--list {
      margin-left: 0;
      padding-left: 20px;
      padding-right: 24px;
      list-style: none;
    }

    &--item {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #E4E4E4;
      margin-bottom: 15px;

      &-icon {
        margin-right: 6px;
      }
    }
  }
}

.section {
  &__headline {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    text-align: center;

    color: $color_section_title;

    margin-bottom: 50px;
  }
}

.process {
  &__list {
    display: flex;
    justify-content: left;
    flex-flow: row wrap;
    padding: 0 65px;
    margin: 0 auto 50px;
    list-style: none;

    @include for-phone-only {
      padding: 0 20px;
    }
  }

  &__item {
    max-width: 45%;
    margin-right: 15px;
    margin-bottom: 5px;
    flex: 50%;

    @include for-phone-only {
      flex: 100%;
      max-width: 100%;
    }

    &--icon {
      font-size: 60px;
      color: rgba(0, 0, 0, 0.15);
      flex: 10%;

      @include for-phone-only {
        font-size: 53px;
      }
    }

    &--text {
      flex: 90%;
      margin-top: 35px;

      @include for-phone-only {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }

    &--layer {
      flex: 90%;
      margin-top: 25px;
      margin-left: 11px;
    }
  }
}

.service-list {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  padding-left: 160px;
  padding-right: 160px;

  @include for-phone-only {
    padding-left: 70px;
    padding-right: 70px;
  }

  &__item {
    display: contents;
    flex-basis: 33.333333%
  }
}

.car-text {
  display: flex;
  max-width: 810px;
  margin: 0 auto 10rem;

  @include for-phone-only {
    margin: 3rem auto 3rem;
  }

  &--icon {
    color: #DCDCDC;
    font-size: 170px;
    z-index: 1;
    text-align: center;
    display: block;
    margin: 0 auto 4rem;

    @include for-phone-only {
      font-size: 100px;
      left: 30px;
      align-content: center;
      align-items: center;
      align-self: center;
    }
  }

  &--text {
    z-index: 2;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 140px;

    color: $color_call2_action;

    @include for-phone-only {
      max-width: 280px;
      margin: 0 auto;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.feedback {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: 3rem;

  &__item {
    display: flex;
    flex-direction: column;
    width: 350px;
    margin: 0 1rem 1rem 1rem;
    padding-left: 7px;
    padding-right: 7px;

    background: #262626;
    box-shadow: 0 4px 4px rgba(80, 80, 80, 0.15);
    border-radius: 5px;

    &--topBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &--stars {
      padding: 5px;
      color: #E9B226;
      font-size: 16px;
    }

    &--name {
      padding-top: 4px;

      h1, h2, h3, h4 {
        margin: 0;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;

        color: #f1f1f1;
      }

      small {
        position: relative;
        top: -8px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        padding-left: 2px;

        color: #e3e3e3;
      }
    }

    &--flexFull {
      width: 100%;
      padding-bottom: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;

      color: #f1f1f1;
    }
  }
}

.ani_item_list {
  display: flex;
  margin: 4rem 0;

  video {
    border-radius: 25px;
    @include for-phone-only {
      display: none;
    }
  }

  .ani_item {
    align-self: center;

    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
}

</style>
