<template>
  <div class="contact">
    <div class="contact__row">
      <div class="contact-wrapper">
        <h1 class="contact-half-title">Kontaktformular</h1>
        <div class="form">
          <input type="text" class="textfield textfield--contact textfield--half" v-model="name" placeholder="Name*">
          <input type="text" class="textfield textfield--contact textfield--half" v-model="telefon"
                 placeholder="Telefon*">
          <input type="text" class="textfield textfield--contact textfield--full" v-model="email" placeholder="Email*">
          <input type="text" class="textfield textfield--contact textfield--full" v-model="subject"
                 placeholder="Betreff*">
          <textarea class="textfield textfield--contact textfield--full textfield--message" v-model="body"
                    placeholder="Nachricht*" maxlength="2048"/>
          <button type="submit" class="submit--btn" @click="recaptcha">Absenden</button>
        </div>
      </div>
    </div>
    <div class="contact__row contact__divider"></div>
    <div class="contact__row">
      <div class="contact-wrapper" id="contact">
        <div>
          <h1 class="contact-half-title">Kontaktmöglichkeiten</h1>
          <img src="/images/logo.png" style="margin: 5px;width: 164px;"/>
          <p @click="openLink('tel://017631070495')" class="contact--information">
            <font-awesome-icon icon="arrow-right"/>
            Rufe uns an
          </p>
          <p @click="openLink('tel://017631070495')" class="contact--information">
            <font-awesome-icon icon="arrow-right"/>
            Festnetz
          </p>
          <p @click="openLink('https://www.google.com/maps/place/B%C3%B6ttgerstra%C3%9Fe+7,+13357+Berlin')"
             class="contact--information">
            <font-awesome-icon icon="arrow-right"/>
            Finde uns
          </p>
          <p @click="openLink('mailto://info@mein-gutachter24.com?subject=Kontaktanfrage')"
             class="contact--information">
            <font-awesome-icon icon="arrow-right"/>
            Kontaktiere uns
          </p>
          <p @click="openLink('https://wa.me/+491625112511')" class="contact--information">
            <font-awesome-icon icon="arrow-right"/>
            Schreibe uns auf WhatsApp
          </p>
        </div>
        <div>
          <div class="contact__row--social">
            <div class="contact__row--social-block" @click="openLink('https://wa.me/+491625112511')">
              <font-awesome-icon class="icon" :icon="['fab', 'whatsapp']"/>
              <span>WhatsApp</span>
            </div>
            <div class="contact__row--social-block" @click="openLink('https://wa.me/+491625112511')">
              <font-awesome-icon class="icon" :icon="['fab', 'instagram']"/>
              <span>Instagram</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Axios from "axios";

export default {
  name: 'Contact',
  data() {
    return {
      name: "",
      telefon: "",
      email: "",
      subject: "",
      body: "",
    }
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('submit')
      Axios.defaults.headers.common['X-Recaptcha-Token'] = token;
      Axios.post("http://localhost:9091/contact", {
        name: this.name,
        telefon: this.telefon,
        email: this.email,
        subject: this.subject,
        body: this.body
      }).then((r) => {
        if (r.status === 200) {
          alert('erfolgreich versendet')
        }else {
          alert('es ist ein Fehler passiert, bitte probiere es erneut')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.contact {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 440px;
  background-color: var(--cal2);
  padding-bottom: 3rem;

  @include for-phone-only {
    flex-direction: column;
    justify-content: center;
  }

  &__row {
    &--social {
      width: 100%;
      padding: 2rem;

      &-block {
        display: inline-flex;
        align-content: center;
        align-items: center;
        padding: .5rem 1rem;
        border-radius: 6px;
        background: #e8e8e8;
        color: $color_call2_action_2;
        cursor: pointer;
        margin-right: 1rem;
        margin-bottom: 1rem;
        font-weight: bold;
        &:hover {
          background: white;
        }
      }

      .icon {
        margin-right: .5rem;
        color: $color_call2_action_2;
        font-size: 32px;
      }
    }

    &:first-child {
      width: 40%;

      @include for-phone-only {
        width: 100%;
      }
    }

    &:nth-child(2) {
      @include for-big-desktop-up {
        display: inline-flex;
      }
    }

    &:last-child {
      width: 45%;
      @include for-phone-only {
        width: 100%;
      }
    }
  }

  &__divider {
    height: 0;
    width: 227px;
    border: 1px solid #FAFAFA;
    transform: rotate(90deg);
    display: none;
    justify-content: center;
    align-content: center;
    align-items: center;
    align-self: center;
  }

  &--information {
    color: white;
    cursor: pointer;
  }

  &--divider {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
    border-left-style: solid;
    border-left-color: white;
  }
}

.contact-half {
  display: inline-block;
  width: 50%;
}

.contact-wrapper {
  display: block;
  margin-left: 35px;
  margin-right: 35px;
}

#contact {
  display: flex;

  > div {
    width: 50%;
    flex: 50%;

    &:nth-child(2) {
      align-self: end;
    }
  }
}

.contact-half-title {
  margin-top: 60px;
  font-family: Monte, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
}

.form {
  display: flex;
  flex-wrap: wrap;

  .submit--btn {
    font-family: Monte, serif;
    font-style: normal;
    font-weight: 700;
    margin-top: 1rem;
    border-color: #29a3ac;
    border-radius: 6px;
    padding: .5rem 1.5rem;
    color: $color_base_bg;
    background-color: $color_secondary;
    margin-left: auto;
    margin-right: 16px;
  }
}

.textfield {
  &--contact {
    margin-right: 16px;
    margin-top: 13px;
  }

  flex: 2 2 45%;

  &--full {
    flex: 100%;
  }
}

</style>
